
export default {
  data () {
    return {
      // 下面数据明细-表头第一层
      tableTopColumns: [
        BaseTableColumn.initData('', '设备信息').setH5ColSpan(4),
        BaseTableColumn.initData('', '省/市/区/小区').setH5ColSpan(4),
        BaseTableColumn.initData('', '点位信息').setH5ColSpan(4),
        BaseTableColumn.initData('', '端口').setH5ColSpan(2),
        BaseTableColumn.initData('', '有效充电订单').setH5ColSpan(3),
        BaseTableColumn.initData('', '人工退款单').setH5ColSpan(2)
      ],
      // 下面数据明细-表头第二层
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('deviceNumber', '设备编码').setSpan({ canCopy: true }),
        BaseTableColumn.initData('deviceName', '设备名称'),
        BaseTableColumn.initData('activeTimeStr', '安装日期'),
        BaseTableColumn.initData('provinceName', '省份'),
        BaseTableColumn.initData('cityName', '城市'),
        BaseTableColumn.initData('districtName', '区域'),
        BaseTableColumn.initData('communityName', '小区名称'),
        BaseTableColumn.initData('siteName', '点位信息'),
        BaseTableColumn.initData('sitePositionDesc', '位置'),
        BaseTableColumn.initData('canopyStatusDesc', '雨棚状态'),
        BaseTableColumn.initData('operateTypeDesc', '运营类型'),
        BaseTableColumn.initData('portUseRate', '使用率').setSpan({ canTextUnit: 'p' }),
        BaseTableColumn.initData('enabledPortNum', '总数'),
        BaseTableColumn.initData('availableOrderNum', '总单数'),
        BaseTableColumn.initData('availableOrderAmount', '总消费金额'),
        BaseTableColumn.initData('customerAvgAmount', '客单价'),
        BaseTableColumn.initData('manualRefundOrderNum', '总单数'),
        BaseTableColumn.initData('manualRefundOrderAmount', '总金额')
      ]
    };
  }
};

import BaseModel from '@/libs/base/dataModel/BaseModel';

export class DeviceAnalysisTotalModel extends BaseModel {
  constructor () {
    super();
    // 统计日期开始
    this.statisDateBegin = '';
    // 统计日期结束
    this.statisDateEnd = '';
    // 端口使用率
    this.portUseRate = '';
    // 充电桩总数
    this.enabledDeviceNum = '';
    // 端口总数
    this.portNum = '';
    this.enabledPortNum = '';
    // 有效充电订单数
    this.availableOrderNum = '';
    // 充电订单总数
    this.orderNum = '';
    // 充电币购买订单数
    this.orderNumChargeCoin = '';
    // 充电币购买订单总金额
    this.orderAmountChargeCoin = '';
    // 新装充电桩总数
    this.activeNum = '';
    // 充电订单分账金额（微信)-服务商
    this.orderAllocateAmountSpWechat = '';
    // 充电订单分账金额（微信)-物业公司
    this.orderAllocateAmountPcWechat = '';
    // 充电订单分账金额（微信)-代理商
    this.orderAllocateAmountAgentWechat = '';
    // 充电订单分账金额（充电币)-服务商
    this.orderAllocateAmountSpCoin = '';
    // 充电订单分账金额（充电币)-物业公司
    this.orderAllocateAmountPcCoin = '';
    // 充电订单分账金额（充电币)-代理商
    this.orderAllocateAmountAgentCoin = '';
    // 充电币购买订单分账金额-服务商
    this.coinOrderAllocateAmountSp = '';
    // 充电币购买订单分账金额-物业公司
    this.coinOrderAllocateAmountPc = '';
    // 充电币购买订单分账金额-代理商
    this.coinOrderAllocateAmountAgent = '';
    // 平均分账比例-充电订单
    this.orderAllocateRateSp = '';
    // 平均分账比例-充电币购买订单
    this.coinOrderAllocateRateSp = '';
    // 人工退款订单数
    this.manualRefundOrderNum = '';
    // 人工退款订单金额
    this.manualRefundOrderAmount = '';
    // 有效充电订单消费金额
    this.availableOrderAmount = '';
    // 客单价
    this.customerAvgAmount = '';
  }

  initData (resData) {
    super.initData(resData);
    // console.log(resData.availableOrderAmount);
    // console.log(this.availableOrderAmount);
    this.portUseRate = Number((this.portUseRate * 100).toFixed(2));
    this.coinOrderAllocateRateSp = Number((this.coinOrderAllocateRateSp * 100).toFixed(2));
    return this;
  }

  static createFakeData () {
    return {
      statisDateBegin: '2024-07-01',
      statisDateEnd: '2024-07-01',
      portUseRate: parseFloat((Math.random() * 99999).toFixed(2)),
      enabledDeviceNum: parseFloat((Math.random() * 99999).toFixed(0)),
      portNum: parseFloat((Math.random() * 99999).toFixed(0)),
      availableOrderNum: parseFloat((Math.random() * 99999).toFixed(0)),
      orderNum: parseFloat((Math.random() * 99999).toFixed(0)),
      orderNumChargeCoin: parseFloat((Math.random() * 99999).toFixed(0)),
      orderAmountChargeCoin: parseFloat((Math.random() * 99999).toFixed(2)),
      activeNum: parseFloat((Math.random() * 99999).toFixed(0)),
      orderAllocateAmountSpWechat: parseFloat((Math.random() * 99999).toFixed(2)),
      orderAllocateAmountPcWechat: parseFloat((Math.random() * 99999).toFixed(2)),
      orderAllocateAmountAgentWechat: parseFloat((Math.random() * 99999).toFixed(2)),
      orderAllocateAmountSpCoin: parseFloat((Math.random() * 99999).toFixed(2)),
      orderAllocateAmountPcCoin: parseFloat((Math.random() * 99999).toFixed(2)),
      orderAllocateAmountAgentCoin: parseFloat((Math.random() * 99999).toFixed(2)),
      coinOrderAllocateAmountSp: parseFloat((Math.random() * 99999).toFixed(2)),
      coinOrderAllocateAmountPc: parseFloat((Math.random() * 99999).toFixed(2)),
      coinOrderAllocateAmountAgent: parseFloat((Math.random() * 99999).toFixed(2)),
      orderAllocateRateSp: parseFloat((Math.random() * 99999).toFixed(2)),
      coinOrderAllocateRateSp: parseFloat((Math.random() * 99999).toFixed(2))
    };
  }
}

export class DeviceAnalysisListModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    // 设备编码
    this.deviceNumber = '';
    // 设备名称
    this.deviceName = '';
    // IMEI号
    this.imei = '';
    // SIM卡ID
    this.iccid = '';
    // 省
    this.provinceName = '';
    // 市
    this.cityName = '';
    // 区县
    this.districtName = '';
    // 小区名称
    this.communityName = '';
    // 物业SAP项目编码
    this.pcSapCode = '';
    // 点位名称
    this.siteName = '';
    // 安装时间
    this.activeTimeStr = '';
    // 运营类型描述
    this.operateTypeDesc = '';
    // 设备商名称
    this.deviceProviderName = '';
    // 端口使用率
    this.portUseRate = '';
    // 端口总数
    this.portNum = '';
    // 启用状态的端口数量
    this.enabledPortNum = '';
    // 累计离线次数
    this.offlineNum = '';
    // 有效充电订单数
    this.availableOrderNum = '';
    // 充电订单总数
    this.orderNum = '';
    // 有效充电订单数-微信
    this.orderNumWechat = '';
    // 有效充电订单数-充电币
    this.orderNumChargeCoin = '';
    // 有效充电订单金额-微信
    this.orderAmountWechat = '';
    // 有效充电订单金额-充电币
    this.orderAmountChargeCoin = '';
    // 充电订单分账金额-微信-服务商
    this.orderAllocateAmountSpWechat = '';
    // 充电订单分账金额-微信-物业公司
    this.orderAllocateAmountPcWechat = '';
    // 充电订单分账金额-微信-代理商
    this.orderAllocateAmountAgentWechat = '';
    // 充电订单分账金额-充电币-服务商
    this.orderAllocateAmountSpCoin = '';
    // 充电订单分账金额-充电币-物业公司
    this.orderAllocateAmountPcCoin = '';
    // 充电订单分账金额-充电币-代理商
    this.orderAllocateAmountAgentCoin = '';
    // 充电币购买订单分账金额-服务商
    this.coinOrderAllocateAmountSp = '';
    // 充电币购买订单分账金额-物业公司
    this.coinOrderAllocateAmountPc = '';
    // 充电币购买订单分账金额-代理商
    this.coinOrderAllocateAmountAgent = '';
    // 有效充电订单消费金额
    this.availableOrderAmount = '';
    // 认领充电币购买订单数
    this.claimCoinOrderNum = '';
    // 认领充电币购买订单总金额
    this.claimCoinOrderAmount = '';
    // 人工退款订单数
    this.manualRefundOrderNum = '';
    // 人工退款订单金额
    this.manualRefundOrderAmount = '';
    // 点位位置
    this.sitePositionDesc = '';
    // 雨棚状态
    this.canopyStatusDesc = '';
    // 客单价
    this.customerAvgAmount = '';

    // 前端专用 ==========>
    // 是否已选中
    this._checked = false;
    // 勾选的主键
    this._checkId = '';
    // 前端专用 ==========>
  }

  initData (resData) {
    super.initData(resData);
    this._checkId = this.id;
    this.portUseRate = Number((this.portUseRate * 100).toFixed(2));
    if (resData.activeTimeStr) {
      this.activeTimeStr = resData.activeTimeStr.split(' ')[0].replaceAll('-', '.');
    }
    return this;
  }

  static createFakeDataList (len = 30) {
    const list = [];
    const operateType = ['自有', '共建', '外拓'];
    for (let i = 0; i < len; i++) {
      const dic = {
        orderDate: '2024-01-01',
        propertyCompanyName: BaseModel.getFakeTitle(10),
        communityCode: 123,
        singlePayNum: 10,
        singlePayTotalAccount: BaseModel.getFakeNumber().toFixed(0),
        singleRefundTotalAccount: BaseModel.getFakeNumber().toFixed(0),
        singlePayFundsReceived: BaseModel.getFakeNumber(),
        payChannelServiceDisburse: BaseModel.getFakeNumber(),
        sysChannelServiceDisburse: BaseModel.getFakeNumber(),
        serviceProviderSubAccount: BaseModel.getFakeNumber(),
        agentSubAccount: BaseModel.getFakeNumber(),
        canSubAccount: BaseModel.getFakeNumber(),
        propertyCompanySubAccount: BaseModel.getFakeTitle(6),
        deviceName: BaseModel.getFakeTitle(6),
        deviceNumber: 'jkkkldjfjsldf' + i,
        imei: 'fdsafsfafdsfadf' + i,
        iccid: 'rwerqwreqwr' + i,
        provinceName: BaseModel.getFakeTitle(3),
        cityName: BaseModel.getFakeTitle(3),
        districtName: BaseModel.getFakeTitle(3),
        communityName: BaseModel.getFakeTitle(8),
        pcSapCode: 'heiufuhiwehfiq' + i,
        siteName: BaseModel.getFakeTitle(6),
        activeTimeStr: '2024-01-01',
        operateTypeDesc: operateType[i % 3],
        deviceProviderName: BaseModel.getFakeTitle(8),
        portUseRate: i % 3,
        portNum: BaseModel.getFakeNumber(),
        offlineNum: BaseModel.getFakeNumber(),
        availableOrderNum: BaseModel.getFakeNumber(),
        orderNum: BaseModel.getFakeNumber(),
        orderNumWechat: BaseModel.getFakeNumber(),
        orderNumChargeCoin: BaseModel.getFakeNumber(),
        orderAmountWechat: BaseModel.getFakeNumber(),
        orderAmountChargeCoin: BaseModel.getFakeNumber(),
        orderAllocateAmountSpWechat: BaseModel.getFakeNumber(),
        orderAllocateAmountPcWechat: BaseModel.getFakeNumber(),
        orderAllocateAmountAgentWechat: BaseModel.getFakeNumber(),
        orderAllocateAmountSpCoin: BaseModel.getFakeNumber(),
        orderAllocateAmountPcCoin: BaseModel.getFakeNumber(),
        orderAllocateAmountAgentCoin: BaseModel.getFakeNumber(),
        coinOrderAllocateAmountSp: BaseModel.getFakeNumber(),
        coinOrderAllocateAmountPc: BaseModel.getFakeNumber(),
        coinOrderAllocateAmountAgent: BaseModel.getFakeNumber(),
        claimCoinOrderNum: BaseModel.getFakeNumber(),
        claimCoinOrderAmount: BaseModel.getFakeNumber(),
        customerAvgAmount: BaseModel.getFakeNumber(5)
      };
      list.push(dic);
    }
    return list;
  }
}
